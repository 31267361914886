.form {
    width: 95%;
    max-width: 25rem;
    margin: 2rem auto;
  }
  

.starting {
    margin: 10rem auto;
    text-align: center;
  }
  
  .starting h1 {
    font-size: 5rem;
  }

.app {
  margin: 4rem auto;
  display: flex;
  justify-content: space-evenly;
}

.app_new {
  width: 90%;
  margin: 4rem auto;
  min-height: 300px;
  display: flex;
  justify-content: space-evenly;
}

.scoresection {
  display: flex;
  font-size: 24px;
  align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.questionsection {
  width: 100%;
  position: relative;
  max-width: 20rem;
  margin: 2rem auto;
}

.questioncount {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  max-width: 20rem;
  margin: 2rem auto;
}

.questioncount span {
  font-size: 28px;
}

.questiontext {
  margin-bottom: 12px;
}

.timertext {
  text-align: center;
  margin: 4rem auto;
  display: flex;
  justify-content: space-evenly;
}

/* ANSWERS/RIGHT SECTION */
.answersection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 20px;
}

.answersection button {
  background: #136f74;
  color: white;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.answersection button:hover {
  background-color: #358686;
  color: #170665d0;
}

/* blog previews / list */
.blog {
  padding: 10px 16px;
  margin: 40px 0;
  border-bottom: 1px solid #fafafa;
}
.blog:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}
.blog h2 {
  font-size: 20px;
  color: #13dfc3;
  margin-bottom: 8px;
}
.blog a {
  text-decoration: none;
}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.cb {
  max-width: 100px;
  margin: 20px auto;
  padding: 20px;
}

.qi {
  /*max-width: 50rem;
  max-height: 50rem;*/
  height: 75%;
  width: 75%;
  padding: 20px;
}

.create {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.create label {
  text-align: left;
  display: block;
}
.create h2 {
  font-size: 20px;
  color: #13dfc3;
  margin-bottom: 30px;
}
.create input,
.create textarea,
.create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.create button {
  background: #117e96;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
