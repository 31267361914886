
.Result {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    
  table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
  }
    
  th {
    border-bottom: 1px solid black;
  }
    
  td {
    text-align: center;
  }

  .content {
    max-width: 700px;
    margin: 40px auto;
    padding: 30px;
  }
