.starting {
  margin: 5rem auto;
  text-align: center;
  margin-bottom: 20px;
}

.starting h1 {
  font-size: 2rem;
}

iframe {
  margin: 2rem auto;
  height: 400px;
  min-width: 100%;
}

.search {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

/* blog previews / list */
.blog {
  padding: 10px 16px;
  margin: 40px 0;
  border-bottom: 1px solid #fafafa;
}
.blog:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}
.blog h2 {
  font-size: 20px;
  color: #13dfc3;
  margin-bottom: 8px;
}
.blog a {
  text-decoration: none;
}

.content {
  max-width: 700px;
  margin: 40px auto;
  padding: 30px;
}

.create {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.create label {
  text-align: left;
  display: block;
}
.create h2 {
  font-size: 20px;
  color: #13dfc3;
  margin-bottom: 30px;
}
.create input,
.create textarea,
.create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}
.create button {
  background: #117e96;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.imagecontent {
  max-width: 50rem;
  max-height: 50rem;
  height: 100%;
  width: 50%;
}